﻿/*Fonts*/

/*Font declaration*/
$muliFont: 'Muli', sans-serif;
$archivoFont: 'Archivo Narrow', sans-serif;
$oswald: 'Oswald', sans-serif;
$opensans: 'Lato', sans-serif;


:root {
    --white: #ffffff;
}
/*Color declaration*/
$brand-color: #0073B1;
$brand-light-color: #056FB2;
$brand-ligher-color: #25A4E1;
$brand-dark-color: #18447D;
$brand-black-color: #00263D;
$brand-blue-dark: #15467B;
$grey-background-color: #F2F2F2;
$grey-text-color: #4D4D4D;
$dark-grey-color: #808080;
$input-color: #666666;
$white-color: #ffffff;
$white: #ffffff;
$text-blue-color: #00253E;
$black-color: #000000;
$black: #000000;
$sky-blue-color: #96D4E8;
$btn-grey-bg: #F7F7F7;
$blue-light: #4171A7;
$blue-light-btn: #53A6DB;
$hard-pink: #F1008D;
$red: #FE0101;
$box-shadow: #00000029;
$arrow-color: #999999;
$humburger-color: #4E5E7C;
$tab-color: #152E45;
$blue: #14467a;
$light-blue: #25a4e1;
$alert-red: #b72020;
$home-blue: #00253d;
$dark: #444444;
$teal: #abddd5;
$gray: #cccccc;
$color-cerulean: #15a7e0;
$color-snow: #f9f9f9;
$skyblue: #15a7e0;
$mortgage-blue: #084875;
$brand-blue-dark: #15467B;
$text-blue-color: #00253E;
$light-gray: #f3f3f3;
$brand-dark-blue: #041645;
/*Muli font styles*/
@mixin muli-regular($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 400;
    font-size: $font-size;
}


@mixin muli-semibold($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 600;
    font-size: $font-size;
}

@mixin muli-bold($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 700;
    font-size: $font-size;
}
/*Muli font style ends*/

/*Archivo font styles*/

@mixin archivo-regular($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 500;
    font-size: $font-size;
}


@mixin archivo-semibold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 600;
    font-size: $font-size;
}


@mixin archivo-bold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 700;
    font-size: $font-size;
}

@mixin ie-only {
    @media all and (-ms-high-contrast: none) {
        @content;
    }
}
/*Archivo font styles end*/

@mixin animation($name: null, $len: null, $ease: null, $delay: null, $fill: null, $delayAdjuster: null) {
    @if $name != null {
        animation-name: $name;
    }

    @if $len != null {
        animation-duration: $len;
    }

    @if $ease != null {
        animation-timing-function: $ease;
    }

    @if $fill != null {
        animation-fill-mode: $fill;
    }

    @if $delay != null {
        @if $delayAdjuster != null {
            animation-delay: $delay + $delayAdjuster;
        }

        animation-delay: $delay;
    }
}

$media: ( firefoxSmallest: 340px, small-mobile: 400px, mobile: 520px, large-mobile: 600px, small-tablet: 670px, tablet: 768px, big-tablet: 850px, wrapper-break: 960px, laptop: 1024px, large-laptop: 1150px, small-desktop: 1250px, medium-desktop: 1325px, large-desktop: 1400px, desktop: 1920px, );

@mixin firefox-smallest {
    @media (max-width: #{map-get($media, firefoxSmallest)}) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: #{map-get($media, small-mobile)}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{map-get($media, mobile)}) {
        @content;
    }
}


@mixin tablet {
    @media (max-width: #{map-get($media, tablet)}) {
        @content;
    }
}

@mixin large-mobile {
    @media (max-width: #{map-get($media, large-mobile)}) {
        @content;
    }
}

@mixin small-tablet {
    @media (max-width: #{map-get($media, small-tablet)}) {
        @content;
    }
}

@mixin big-tablet {
    @media (max-width: #{map-get($media, big-tablet)}) {
        @content;
    }
}

@mixin wrapper-break {
    @media (max-width: #{map-get($media, wrapper-break)}) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: #{map-get($media, laptop)}) {
        @content;
    }
}

@mixin large-laptop {
    @media (max-width: #{map-get($media, large-laptop)}) {
        @content;
    }
}

@mixin small-desktop {
    @media (max-width: #{map-get($media, small-desktop)}) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: #{map-get($media, medium-desktop)}) {
        @content;
    }
}

@mixin large-desktop {
    @media (max-width: #{map-get($media, large-desktop)}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{map-get($media, desktop)}) {
        @content;
    }
}

@mixin animate($target, $speed, $type) {
    -webkit-transition: $target $speed $type;
    -moz-transition: $target $speed $type;
    -ms-transition: $target $speed $type;
    -o-transition: $target $speed $type;
    transition: $target $speed $type;
}