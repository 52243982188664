﻿@import "var";


.bg-grey{
    background-color: $grey-background-color;
    *{
        color : $text-blue-color;
    }
}
.bg-white {
    background-color: $white-color;

    * {
        color: $text-blue-color;
    }
}
.bg-dark-blue {
    background-color: $brand-black-color;

    * {
        color: $white-color;
    }
}
.bg-dark-dark-blue {
    background-color: $brand-blue-dark;

    * {
        color: $white-color;
    }
}
.bg-light-blue {
    background-color: $brand-color;

    * {
        color: $white-color;
    }
}
.bg-blue {
    background-color: $brand-blue-dark;

    * {
        color: $white-color;
    }
}

.bg-color-white {
    background-color: $white-color;
}

.bg-color-dark-blue {
    background-color: $brand-blue-dark;
}

.bg-color-light-blue {
    background-color: $brand-ligher-color;
}
